import React from 'react';
import Layout from "../../components/Layout";
import NavOne from "../../components/NavOne";
import PageHeader from "../../components/PageHeader";
import AboutOne from "../../components/History";
import Footer from "../../components/Footer";

const AboutPage = () => {
    return (
        <Layout pageTitle="Our Mandate | LUTH">
            <NavOne />
            <PageHeader title="" />
            <AboutOne />            
            <Footer />
        </Layout>
    );
};

export default AboutPage;
