import React, { Component } from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import ClientStripe from '../assets/images/circle-stripe.png';
import aboutImage1 from '../assets/images/about-2-1.jpg';
import { Link } from 'gatsby';
import aboutImage2 from '../assets/images/about-2-2.jpg';

class AboutOne extends Component {
  constructor() {
    super();
    this.state = {
      startCounter: false,
    };
  }

  onVisibilityChange = (isVisible) => {
    if (isVisible) {
      this.setState({ startCounter: true });
    }
  };

  render() {
    return (
      <section className='about-one '>
        <img src={ClientStripe} className='about-one__circle' alt='' />
        <div className='container text-center'>
          <div className='block-title text-center'>
            <h2 className='block-title__title'>
              Lagos University Teaching Hospital (LUTH)
            </h2>
          </div>
          <p className='about-one__text'>
            The Lagos University Teaching Hospital (LUTH) and the Medical School
            Complex was enacted by a Cabinet decision made in April of 1961
            following recommendations made by the Sir Eric Ashby's commission on
            Post-Secondary Education in Nigeria.
            <br />
            These Cabinet Committee had Mr. Aja Nwachuckwu (Minister of
            Education), Dr. T. O.Elias (Attorney-General and Minister of
            Justice), Mr. Olu Akinfosile (Minister of Communications), Alhaji
            Shehu Shagari (Minister of Establishments) and Senator (Dr) M.A.
            Majekodunmi (Minister of Health) as members.
            <br />
            <br />
            The objectives were to:
            <br />
            <strong>-</strong> Train at least 100 Doctors annually in Medical
            Schools in Nigeria from 1975 onwards.
            <br />
            <strong>-</strong> Provide effective and rapid re-organization of
            hospitals in Lagos, Surulere, Ibadan, Kaduna and Enugu for teaching
            clinical medicine.
            <br />
            <strong>-</strong> Establish a full-fledged Medical School in Lagos
            as soon as possible to make use of the existing medical
            institutions.
            <br />
            <br />
            The site now occupied by LUTH and CMUL was a ninety-two (92) acre
            field of farmland, formally occupied by the Mainland Hospital. The
            completed buildings of the new Surulere Mainland Hospital were
            adapted to suit the needs of a Teaching Hospital/Medical School
            Complex. The energy and enthusiasm of the Minister of Health enabled
            this task to be accomplished in record time.
          </p>
          <h2 className='block-title__title'>Locations</h2>
          <h2
            style={{ fontSize: 24, marginTop: 30 }}
            className='block-title__title'
          >
            LUTH Main Campus, Idi-Araba
          </h2>
          <p className='about-one__text' style={{ marginTop: 20 }}>
            We are reachable at our main campus and on social media at the
            addresses below. Lagos University Teaching Hospital, Ishaga Road,
            Idi-Araba, Lagos, Nigeria.
            <br />
            www.luth.gov.ng
            <br />
            +2348070591395 (Corporate), +2348023115885 (CMAC)
          </p>
          <div className='block-title'>
            <h2
              style={{ fontSize: 24, marginTop: 30 }}
              className='block-title__title'
            >
              LUTH Psychiatry Clinic, Yaba
            </h2>
          </div>
          <div className='block-title'>
            <h2
              style={{ fontSize: 24, marginTop: 30 }}
              className='block-title__title'
            >
              LUTH Dermatology Clinic, Yaba
            </h2>
          </div>
          <div className='block-title'>
            <h2
              style={{ fontSize: 24, marginTop: 30 }}
              className='block-title__title'
            >
              LUTH PHC, Pakoto, Ogun State
            </h2>
          </div>
          <p className='about-one__text'>
            Over the years the Lagos University Teaching Hospital has remained
            one of the best Teaching/Specialist Hospitals in the country. The
            hospital is designated a centre of excellence in Dentistry. A major
            breakthrough of the successful conception and delivery of the first
            authentic test tube (In-Vitro-Fertilization) baby was performed by
            the duo of Professor Osato Frank Giwa-Osagie and Professor Oladapo
            Ashiru at the Lagos University Teaching Hospital. The hospital also
            renders services in specialized areas of Medicine like
            Neuro-Surgery, Haemo-dialysis, Radiation Oncology, Ophthalmology,
            Obstetrics and Gynaecology, Cardiothoracic Surgery, Urology,
            Paediatric Surgery, Maxillofacial Surgery, Ear Nose and Throat
            Surgery, Haematology, Medical Microbiology, Community Health, Child
            Dental Health, Restorative Dentistry, Maternal Health, Psychiatry,
            Orthopaedic Surgery etc.
            <br />
            <br />
            LUTH has the highest concentration of skilled medical and
            paramedical staff in different areas of medicine. This explains the
            reason why LUTH is always the focus when foreign countries, oil
            companies and even highbrow private hospitals in Nigeria are on
            recruitment drive for manpower. LUTH has pioneered Small Incision
            Cataract Excision Surgery in Nigeria and has trained over 40 doctors
            from all over the country in the procedure in the past years. The
            hospital has also commenced laparascopic general and gynaecologic
            surgery which has dramatically reduced the duration of
            hospitalization after surgery.
            <br />
            <br />
            LUTH also boasts of a comprehensive Diagnostic Centre and VIP
            Clinic, state of the art Laboratories, Radiodiagnosis, Radiotherapy
            and Renal Dialysis facilities. From a humble beginning of 330 beds,
            LUTH today, is the largest Teaching Hospital in Nigeria with 800
            beds and with the College of Medicine of the University of Lagos,
            LUTH is involved in the training of hundreds of Medical, Dental,
            Pharmacy and other Allied Health Science Students. The hospital has
            produced thousands of Nurses, Laboratory Scientists, Health Records
            Officers, Biomedical Engineers and other para-Medical Cadres.
            Various members of the Consultant staff have made significant
            contributions to the advancement of knowledge in major areas of
            medical science, notably in the separation of conjoined twins and
            in-vitro fertilization. <br />
            <br />
            LUTH has also taken advantage of the FGN Public Private Partnership
            Initiatives, and presently partners with private organizations in
            providing state-of-the-art facilities. The flagship of such
            partnership is the multi-million dollars NSIA-LUTH Cancer Centre
            (NLCC), the first of its kind in West Africa. Others are LUTH Blood
            Banking System, Radiodiagnosis Centre, Independent Power Project,
            Sickle Cell Centre/LUTH Bone Marrow Transplant Centre, Olusola Dada
            Dialysis Centre & Renal Institute etc.
            <br />
            <br />
            LUTH is the only Federal Teaching Hospital with Independent Power
            Project that generates its own electricity, and therefore has been
            enjoying uninterrupted power supply since December 2017. <br />
            <br />
            The biggest Renal Institute in Nigeria, Isaac Olusola Dada Dialysis
            Centre and Renal Institute is already commissioned and presently
            functional. Alima Atta Oncology Wards (62-bedded Wards) is near
            completion.
          </p>
          <h2 className='block-title__title'>Clinical Departments In LUTH</h2>
          <div style={{ textAlign: 'left' }}>
            <Link to='/departments/anaesthesia'>
              <h2
                style={{ fontSize: 24, marginTop: 30 }}                
              >
                Department of Anaesthesia
              </h2>
            </Link>
            <Link to='/departments/anatomic-path'>
              <h2
                style={{ fontSize: 24, marginTop: 30 }}                
              >
                Department of Anatomical Pathology
              </h2>
            </Link>
            <Link to='/departments/child-dental'>
              <h2
                style={{ fontSize: 24, marginTop: 30 }}                
              >
                Department of Child Dental Health
              </h2>
            </Link>
            <Link to='/departments/clinical-path'>
              <h2
                style={{ fontSize: 24, marginTop: 30 }}                
              >
                Department of Clinical Pathology
              </h2>
            </Link>
            <Link to='/departments/comm-health'>
              <h2
                style={{ fontSize: 24, marginTop: 30 }}                
              >
                Department of Community Health & Primary Care
              </h2>
            </Link>
            <h2
              style={{ fontSize: 24, marginTop: 30 }}              
            >
              Department of Ear Nose and Throat
            </h2>
            <Link to='/departments/family-medicine'>
              <h2
                style={{ fontSize: 24, marginTop: 30 }}                
              >
                Department of Family Medicine
              </h2>
            </Link>
            <Link to='/departments/haematology'>
              <h2
                style={{ fontSize: 24, marginTop: 30 }}                
              >
                Department of Haematology and Blood Transfusion
              </h2>
            </Link>
            <Link to='/departments/microbio'>
              <h2
                style={{ fontSize: 24, marginTop: 30 }}                
              >
                Department of Medical Microbiology & Parasitology
              </h2>
            </Link>
            <Link to='/departments/obstetrics'>
              <h2
                style={{ fontSize: 24, marginTop: 30 }}                
              >
                Department of Obstetrics and Gynaecology
              </h2>
            </Link>
            <h2
              style={{ fontSize: 24, marginTop: 30 }}              
            >
              Departments of Ophthalmology
            </h2>
            <Link to='/departments/oral-path'>
              <h2
                style={{ fontSize: 24, marginTop: 30 }}                
              >
                Department of Oral and Maxillofacial Pathology/Biology
              </h2>
            </Link>
            <Link to='/departments/oral-surgery'>
              <h2
                style={{ fontSize: 24, marginTop: 30 }}                
              >
                Department of Oral and Maxillofacial Surgery
              </h2>
            </Link>
            <h2
              style={{ fontSize: 24, marginTop: 30 }}              
            >
              Departments of Orthopaedics
            </h2>
            <Link to='/departments/paediatrics'>
              <h2
                style={{ fontSize: 24, marginTop: 30 }}                
              >
                Department of Paediatrics
              </h2>
            </Link>
            <Link to='/departments/preventive-dentistry'>
              <h2
                style={{ fontSize: 24, marginTop: 30 }}                
              >
                Department of Preventive Dentistry
              </h2>
            </Link>
            <Link to='/departments/psychiatry'>
              <h2
                style={{ fontSize: 24, marginTop: 30 }}                
              >
                Department of Psychiatry
              </h2>
            </Link>
            <h2
              style={{ fontSize: 24, marginTop: 30 }}              
            >
              Department of Radiation Oncology
            </h2>
            <h2
              style={{ fontSize: 24, marginTop: 30 }}              
            >
              Department of Radiodiagnosis
            </h2>
            <Link to='/departments/restorative-dentistry'>
              <h2
                style={{ fontSize: 24, marginTop: 30 }}                
              >
                Department of Restorative Dentistry
              </h2>
            </Link>
            <Link to='/departments/surgery'>
              <h2
                style={{ fontSize: 24, marginTop: 30 }}                
              >
                Department of Surgery
              </h2>
            </Link>
            <h2
              style={{ fontSize: 24, marginTop: 30 }}              
            >
              Internal Medicine
            </h2>
          </div>
          <h2
            style={{ fontSize: 24, marginTop: 30 }}
            className='block-title__title'
          >
            Allied Services
          </h2>
          <p className='about-one__text' style={{ marginTop: 20 }}>
            • Medical Social Services
            <br />• Nursing Services
            <br />• Pharmacy
            <br />• Physiotherapy
            <br />• Nutrition & Dietetics
            <br />• Prosthesis & Orthotics
            <br />• Occupational Therapy
          </p>
          <h2
            style={{ fontSize: 24, marginTop: 30 }}
            className='block-title__title'
          >
            LUTH Schools
          </h2>
          <p className='about-one__text' style={{ marginTop: 20 }}>
            There are presently 9 schools offering diploma and certificate
            courses in various fields in LUTH.
            <br /> • School of Nursing
            <br />• School of Midwifery
            <br />• School of Post-basic Nursing
            <br />• Federal School of Biomedical Engineering
            <br />• School of Health Information Management
            <br />• School of Medical and Psychiatry Social Work
            <br />• Anaesthetic Technicians
            <br />• School of Basic Dental Nursing
            <br />• Community Health Officers’ Training School
          </p>
        </div>
      </section>
    );
  }
}

export default AboutOne;
